import React from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Avatar,
  Paper,
  Divider,
} from "@mui/material";

import { ReactComponent as SoftwareDevImage } from "../assets/svg/softwaredev.svg";
import { ReactComponent as CodeIcon } from "../assets/svg/code-solid.svg";
import { ReactComponent as EthereumIcon } from "../assets/svg/ethereum-brands.svg";
import { ReactComponent as TeacherIcon } from "../assets/svg/chalkboard-teacher-solid.svg";

import MyAvatar from "../assets/img/avatar.jpg";

const style = {
  devName: {
    fontFamily: "Michroma",
    fontSize: {
      xs: "1.5rem",
      md: "2.2rem",
    },
    fontWeight: 700,
    textAlign: "center",
  },
  devDescription: {
    fontSize: {
      xs: "1.2rem",
      md: "1.5rem",
    },
    textAlign: "center",
    marginBottom: 5,
  },
  avatar: {
    width: {
      xs: 150,
      md: 200,
    },
    height: {
      xs: 150,
      md: 200,
    },
    marginBottom: 10,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  },
  image1: {
    height: {
      xs: 60,
      sm: 100,
      md: 180,
    },
    color: (theme) => theme.palette.primary.main,
  },
  section1: {
    backgroundColor: (theme) => theme.palette.primary.main,
    paddingBottom: 20,
  },
  greetingGrid: {
    padding: "120px 5px",
  },
  headerStyle1: {
    color: (theme) => theme.palette.common.white,
    fontSize: {
      xs: "1.3rem",
      md: "2.2rem",
    },
    fontWeight: 700,
  },
  subtitleStyle1: {
    color: (theme) => theme.palette.common.white,
    fontSize: {
      xs: "1rem",
      md: "1.5rem",
    },
    textAlign: "center",
    maxWidth: {
      xs: 500,
      md: 800,
    },
  },
  section2: {
    display: "flex",
    justifyContent: "center",
    marginTop: -20,
  },
  paper: {
    width: {
      xs: "95%",
      md: "90%",
    },
    borderRadius: 3,
  },
  skillsContainer: {
    flexWrap: {
      xs: "wrap",
      sm: "nowrap",
    },
  },
  skillIcon: {
    height: 60,
    color: (theme) => theme.palette.primary.main,
  },
  skillName: {
    fontWeight: 700,
  },
  section3: {
    paddingTop: 10,
  },
  headerStyle3: {
    fontSize: {
      xs: "1rem",
      md: "1.8rem",
    },
    fontWeight: 700,
  },
  subtitleStyle2: {
    fontSize: {
      xs: "1rem",
      md: "1.2rem",
    },
    textAlign: "center",
  },

  footerPaper: {
    display: "flex",
    justifyContent: "center",
    marginBottom: -10,
  },
  contactMe: {
    textTransform: "none",
    fontSize: 18,
    borderRadius: 15,
    borderWidth: 2,
    borderColor: (theme) => theme.palette.primary.main,
    "&:hover": {
      backgroundColor: (theme) => theme.palette.primary.main,
      color: (theme) => theme.palette.common.white,
      borderWidth: 2,
    },
  },
};

export default function LandingPage() {
  return (
    <Box>
      <Box sx={{ padding: 2 }}>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <Typography
              variant="h1"
              color="textPrimary"
              data-aos="zoom-in-down"
              data-aos-duration="600"
              data-aos-delay="600"
              sx={style.devName}
            >
              Jonathan Hipolito
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              data-aos="fade-up"
              data-aos-duration="600"
              data-aos-delay="900"
              sx={style.devDescription}
            >
              Software Engineer | Blockchain Developer | College Instructor
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              src={MyAvatar}
              alt="Jonathan"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="1200"
              sx={style.avatar}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="1400"
        sx={style.imageContainer}
      >
        <Box sx={style.image1}>
          <SoftwareDevImage style={{ height: "inherit", color: "inherit" }} />
        </Box>
      </Box>

      <Box sx={style.section1}>
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={style.greetingGrid}
          spacing={2}
        >
          <Grid item>
            <Typography
              variant="h2"
              sx={style.headerStyle1}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              Hi, I'm Nathan. Nice to meet you.
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              sx={style.subtitleStyle1}
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="600"
            >
              I am a passionate programmer that loves to code. I build
              code-efficient website that will satisfy my client. I'm also eager
              to learn about new web trends and technology.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={style.section2}>
        <Paper elevation={3} sx={style.paper}>
          <Grid container sx={style.skillsContainer}>
            <Grid
              item
              xs={12}
              sm={4}
              container
              direction="column"
              alignItems="center"
              sx={{ padding: "32px 5px" }}
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="800"
            >
              <Grid item>
                <Box sx={style.skillIcon}>
                  <CodeIcon style={{ height: "inherit", color: "inherit" }} />
                </Box>
              </Grid>
              <Grid item sx={{ paddingTop: 5 }}>
                <Typography variant="h6" sx={style.skillName} align="center">
                  Software Engineer
                </Typography>
              </Grid>
              <Grid item sx={{ paddingTop: 5 }}>
                <Typography variant="subtitle1" align="center">
                  I like to code things from scratch, and enjoy bringing ideas
                  to life in the browser.
                </Typography>
              </Grid>
              <Grid item sx={{ paddingTop: 3 }}>
                <Typography
                  variant="subtitle1"
                  align="center"
                  color="primary"
                  sx={{ fontWeight: 600 }}
                >
                  Languages I speak:
                </Typography>
              </Grid>
              <Grid item sx={{ paddingTop: 1 }}>
                <Typography variant="subtitle1" align="center">
                  HTML, CSS, JavaScript, PHP, Java
                </Typography>
              </Grid>
              <Grid item sx={{ paddingTop: 3 }}>
                <Typography
                  variant="subtitle1"
                  align="center"
                  color="primary"
                  sx={{ fontWeight: 600 }}
                >
                  Dev tools, libraries & frameworks:
                </Typography>
              </Grid>
              <Grid item sx={{ paddingTop: 1 }}>
                <Typography variant="subtitle1" align="center">
                  VSCode
                  <br />
                  Github
                  <br />
                  Terminal
                  <br />
                  Node JS
                  <br />
                  React JS
                  <br />
                  React Native
                  <br />
                  Firebase
                  <br />
                  Android Studio
                </Typography>
              </Grid>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid
              item
              xs={12}
              sm={4}
              container
              direction="column"
              alignItems="center"
              sx={{ padding: "32px 5px" }}
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="1200"
            >
              <Grid item>
                <Box sx={style.skillIcon}>
                  <EthereumIcon
                    style={{ height: "inherit", color: "inherit" }}
                  />
                </Box>
              </Grid>
              <Grid item sx={{ paddingTop: 5 }}>
                <Typography variant="h6" sx={style.skillName} align="center">
                  Blockchain Developer
                </Typography>
              </Grid>
              <Grid item sx={{ paddingTop: 5 }}>
                <Typography variant="subtitle1" align="center">
                  I build applications based on blockchain architecture and
                  protocol.
                </Typography>
              </Grid>
              <Grid item sx={{ paddingTop: 3 }}>
                <Typography
                  variant="subtitle1"
                  align="center"
                  color="primary"
                  sx={{ fontWeight: 600 }}
                >
                  Languages & tools:
                </Typography>
              </Grid>
              <Grid item sx={{ paddingTop: 1 }}>
                <Typography variant="subtitle1" align="center">
                  Solidity, Truffle, Ganache, Remix
                </Typography>
              </Grid>
              <Grid item sx={{ paddingTop: 3 }}>
                <Typography
                  variant="subtitle1"
                  align="center"
                  color="primary"
                  sx={{ fontWeight: 600 }}
                >
                  Blockchain networks:
                </Typography>
              </Grid>
              <Grid item sx={{ paddingTop: 1 }}>
                <Typography variant="subtitle1" align="center">
                  Ethereum
                  <br />
                  Binance Smart Chain
                  <br />
                  DRK Chain
                </Typography>
              </Grid>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid
              item
              xs={12}
              sm={4}
              container
              direction="column"
              alignItems="center"
              sx={{ padding: "32px 5px" }}
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="1600"
            >
              <Grid item>
                <Box sx={style.skillIcon}>
                  <TeacherIcon
                    style={{ height: "inherit", color: "inherit" }}
                  />
                </Box>
              </Grid>
              <Grid item sx={{ paddingTop: 5 }}>
                <Typography variant="h6" sx={style.skillName} align="center">
                  College Instructor
                </Typography>
              </Grid>
              <Grid item sx={{ paddingTop: 5 }}>
                <Typography variant="subtitle1" align="center">
                  I also love teaching and enjoy helping future developers.
                </Typography>
              </Grid>
              <Grid item sx={{ paddingTop: 3 }}>
                <Typography
                  variant="subtitle1"
                  align="center"
                  color="primary"
                  sx={{ fontWeight: 600 }}
                >
                  Teaching Experiences:
                </Typography>
              </Grid>
              <Grid item sx={{ paddingTop: 1 }}>
                <Typography variant="subtitle1" align="center">
                  HTML, CSS, JS, React JS, PHP, Node JS, Firebase
                </Typography>
              </Grid>
              <Grid item sx={{ paddingTop: 3 }}>
                <Typography
                  variant="subtitle1"
                  align="center"
                  color="primary"
                  sx={{ fontWeight: 600 }}
                >
                  Universities:
                </Typography>
              </Grid>
              <Grid item sx={{ paddingTop: 1 }}>
                <Typography variant="subtitle1" align="center">
                  Bulacan State University
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box sx={style.section3}>
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{ padding: "0px 5px" }}
        >
          <Grid item>
            <Typography
              variant="h3"
              color="textPrimary"
              sx={style.headerStyle3}
              data-aos="fade-down-right"
              data-aos-duration="1000"
            >
              My Recent Work
            </Typography>
          </Grid>
          <Grid item sx={{ paddingTop: 1 }}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              sx={style.subtitleStyle2}
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="600"
            >
              Here are a few projects I've worked on recently. Want to see more?
              Email me.
            </Typography>
          </Grid>
          <Grid item sx={{ padding: 5 }}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              sx={style.subtitleStyle2}
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="800"
            >
              Currently Working....
            </Typography>
          </Grid>

          {/* projects TODO */}
        </Grid>
      </Box>

      <Box sx={style.footerPaper}>
        <Paper
          elevation={3}
          sx={{
            ...style.paper,
            backgroundColor: "#141C3A",
          }}
        >
          <Grid
            container
            sx={{ padding: "64px 10px" }}
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} md={4}>
              <Typography
                variant="h5"
                align="center"
                sx={{
                  color: (theme) => theme.palette.common.white,
                  fontWeight: 700,
                }}
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-delay="600"
              >
                Start a project
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                align="center"
                sx={{ color: (theme) => theme.palette.common.white }}
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-delay="800"
              >
                Interested in working together? We should queue up a chat. I’ll
                buy the coffee.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} container justifyContent="center">
              <Button
                variant="outlined"
                color="primary"
                sx={style.contactMe}
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-delay="1000"
              >
                <Typography
                  sx={{
                    color: (theme) => theme.palette.common.white,
                    padding: 1,
                    fontSize: "1.2rem",
                  }}
                >
                  Let's do this
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
}
